* {
  font-family: Raleway !important;
}

.fontFamilyToNumber {
  font-family: Arial !important;
}

a {
  text-decoration: none !important;
}

.nav-link {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #1780e8 !important;
  text-align: center;
  border-color: initial !important;
}

.nav-link:link {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #1780e8 !important;
  border-color: initial !important;
}

.nav-link:visited {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #1780e8 !important;
  border-color: initial !important;
}

.nav-link:active {
  background-color: transparent !important;
  text-decoration: none !important;
  color: #1780e8 !important;
  border-color: initial !important;
}

.paddingContainer {
  padding: 0;
}

.container-2 {
  min-height: 69vh;
  max-width: 100%;
  padding: 1rem 1rem;
}

#container-questions {
}

.container-cards {
  display: flex;
  gap: 1rem;
  grid-auto-rows: auto;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(max(20%, 13rem), 1fr));
  padding: 0;
  margin: 0;
}

.navbarMobile {
  background-color: white;
  margin-left: 0;
}

.navbar-vertical.navbar-expand-xl.navbar-card .navbar-collapse,
.navbar-vertical.navbar-expand-xl.navbar-card .navbar-vertical-content,
.navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-collapse,
.navbar-vertical.navbar-expand-xl.navbar-vibrant .navbar-vertical-content,
.navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-collapse,
.navbar-vertical.navbar-expand-xl.navbar-inverted .navbar-vertical-content {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.navbar-vertical.navbar-expand-xl.navbar-card .toggle-icon-wrapper,
.navbar-vertical.navbar-expand-xl.navbar-vibrant .toggle-icon-wrapper,
.navbar-vertical.navbar-expand-xl.navbar-inverted .toggle-icon-wrapper {
  margin-right: 2.5rem;
}

#root {
  /*
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center;
    

    background-position-x: 100%;
    background-position-y: 0%;
    background-origin: padding-box;
    background-clip: padding-box;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-color: transparent;
    */

  background-repeat: no-repeat;
  /* background-position: center center; */
  /* background-attachment: fixed; */
  background-size: cover;
}

#formularioEncuesta label {
  font-weight: 400 !important;
}

.div-pregunta {
  display: grid;
  gap: 0.4rem;
  grid-template-columns: minmax(3rem, 3rem) minmax(8rem, 93%);
  padding: 0;
}

.div-pregunta .numeracion-pregunta {
  background: #1780e8;
  color: white;
  border-radius: 10rem;
  padding: 0.2rem 1rem;
  height: max-content;
}

.div-pregunta .descripcion-pregunta {
  display: flex;
  align-items: center;
  /* padding-top: 0.1rem; */
  /* padding-left: 0.3rem; */
}

/*

@keyframes este {
    0%  { background-color: inherit; }
    4%  { background-color: rgba(0, 0, 0, 0.04); }
    80%  { background-color: inherit; }
}

.addCircleIcon{
    width: 100%;
}

.containerAddCircleIcon {
    background-color: inherit;
    animation-name: este;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

*/

.containerCardsFilter {
  display: grid;
  gap: 1rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(max(30%, 14rem), 1fr));
}

.containerTimeFilter {
  display: grid;
  gap: 3rem;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.react-tabs__tab--selected {
  background: #816ddd !important;
  color: white !important;
}

.react-tabs__tab {
  bottom: 0 !important;
}

.react-tabs__tab {
  border: 0 !important;
  border-radius: 0.2rem;
}

.react-tabs__tab-list {
  margin: 0 !important;
}

.footer {
  position: relative;
  /* margin-top: 6rem; */
}

.content {
  padding-bottom: 0;
}

.div-form-check {
  margin: 0.5rem 0 1.5rem 0;
  /* background: #d5e1ec; */
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 10px;
  min-width: 30%;
}

.div-form-check .form-check {
  margin-bottom: 0;
}

.form-check label {
  font-size: 16px;
  margin-bottom: 0;
}

#formularioEncuesta {
  padding: 0.7rem 2rem;
}

#formularioEncuesta .labelRadio {
  margin-left: 1rem;
  font-size: 14px;
}

#formularioEncuesta .labelRadio input {
  margin-right: 0.4rem;
}

.navbar-vertical.navbar-expand-xl + .content .navbar-top {
  background-color: none !important;
}

.navbar-top {
  background-color: white;
}

.navbar-top .navbar-toggler {
  margin-left: 0.375rem;
}

.iconUser {
  font-size: 20px;
}

.navbarPadding {
  background-color: white;
  height: calc(100vh - 9.3125rem) !important;
}

.avatarIcon {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.navbar-vertical-divider {
  min-width: 3rem;
}

.sombraNavbarInhabilitada {
  box-shadow: unset !important;
  margin: 0 !important;
}

.navbar-vertical .toggle-icon-wrapper {
  margin-left: 0.25rem;
}

.divCerrarSesion {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #74e36b;
  height: 4.2rem;
  padding: 5px;
  font-weight: 600;
  cursor: pointer;
}

.d-sm-none {
  display: block;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .divCerrarSesion {
    height: 6.2rem !important;
  }
}

.hrNavBar {
  background-color: #5f5f5f !important;
}

.navbar-vertical .navbar-collapse {
  background-color: white !important;
}

.spanCerrarSesion {
  color: #0f4f80;
}

.iconoCerrarSesion {
  width: 30px;
}

.tituloUsuarioCard {
  color: #1780e8;
}

.navbar-vertical {
  top: 3.3125rem;
}

@media (max-width: 535px) {
  .container-cards {
    display: block;
  }
  .zoom {
    margin-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical-collapsed
    .navbar-vertical.navbar-expand-xl
    .navbar-collapse {
    width: 100% !important;
  }
  /* Css para colocar el icono de cerrar sesion al 80% solamente cuando esté el menú comprimido*/
  .navbar-vertical-collapsed:not(.navbar-vertical-collapsed-hover).navbar-vertical-collapsed
    .navbar-vertical.navbar-expand-xl
    .navbar-collapse
    > .divCerrarSesion
    > .row
    > .col-md-12
    > .iconoCerrarSesion {
    width: 80%;
    transition: transform 0.1s;
  }
  .navbar-vertical.navbar-expand-xl + .content {
    margin-left: 12.6rem;
  }
  .navbar-vertical-collapsed
    .navbar-vertical.navbar-expand-xl
    .navbar-vertical-content {
    padding-left: 0.7rem;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
    padding-left: 0.7rem;
  }
}

@media only screen and (max-width: 1199px) {
  #root {
    background: #655FBF
  }
  #formularioEncuesta {
    padding: 0;
  }

  .div-form-check {
    padding-left: 2rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {
  #root {
    /* background-size: auto !important; */
    /* background-image: url(../../assets/img/vumOfficeImgs/background-form-1.png); */
  }
  .container-2 {
    padding: 0.7rem 0rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
  #root {
    /* background-size: auto !important; */
    /* background-image: url(../../assets/img/vumOfficeImgs/background-form-1.png); */
  }
  .container-2 {
    padding: 0.7rem 0rem;
  }
  .paddingContainer {
    padding: 0px;
  }
  .headerMobile {
    display: flex !important;
  }
  .navbar-glass {
    background-color: white !important;
  }
  .navbar-vertical.navbar-card,
  .navbar-vertical.navbar-vibrant,
  .navbar-vertical.navbar-inverted {
    padding-left: unset;
    padding-right: unset;
  }
  .navbarMobile {
    padding: 0% 0% 0% 0% !important;
    background-color: unset !important;
    margin-left: -15.1px;
  }
  #generalContent {
    padding: 1% !important;
  }
  .d-sm-none {
    display: none;
  }
  /* .navbarPadding {
        max-height: 500px !important;
    } */
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {
  .paddingContainer {
    padding: 0;
  }
  .headerMobile {
    display: flex !important;
  }
  .navbar-glass {
    background-color: white !important;
  }
  .navbar-vertical.navbar-card,
  .navbar-vertical.navbar-vibrant,
  .navbar-vertical.navbar-inverted {
    padding-left: unset;
    padding-right: unset;
  }
  .navbarMobile {
    padding: 0% 0% 0% 0% !important;
    background-color: unset !important;
    margin-left: -15.1px;
  }
  #generalContent {
    padding: 1% !important;
  }
  .d-sm-none {
    display: none;
  }
  /* .navbarPadding {
        max-height: 500px !important;
    } */
}
