.sst {
    background-image: url("../../assets/img/vumOfficeImgs/background-sst.png");
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
}

.info-ult-data{
    width: auto;
    height: auto;
     /* background-color: red;  */
    text-align: start;
}

.h3{
    color: #fff;
    font-size: 15px;
}

.p{
    color: #F7F7F7;
    font-size: 13px;
}
