.mi-billetera {
    background-color: #0F4F80;
}

.btns-billetera {
    background-color: #74E36B;
    color: #0F4F80;
    border: 1px solid #74E36B;
    border-radius: 25px;
    height: 50px;
    width: 100%;
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: .3125rem 1rem;
    margin-right: 30px;
    font-size: 1rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.inputsMiBilletera {
    text-align: center;
    border: 1px solid #1780E8;
    border-radius: 1.5em;
    height: 50px;
    width: 100%;
    display: inline;
    background-color: #E8EAED;
}

.textoSalario{
    color: #74E36B;
    font-size: 20px;
    font-weight: lighter;
}

.textoPpal{
    color: #74E36B;
    font-size: 22px;
    font-weight: lighter;
}

.texto2rio{
    color: #FFFFFF;
    font-size: 17px;
    font-weight: lighter;
}

.dividorPantalla{
    border-right: 0.5px solid #0076D0;
}

.hrDividor{
    color: #0076D0;
    height: 0.5px !important;
}

.imgBeneficios{
    width: 100%;
}

.cardCabeceraImagen{
    border-radius: .375rem;
    background-color: unset;
}

.imagenCabecera{
    border-radius: .375rem;
}

.imgDeleteGasto{
    width: 20px;
    cursor: pointer;
}



@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
    .cardCabeceraImagen{
        display: none;
    }
    .mi-billetera {
        background-color: #0F4F80;
    }
    
}        

@media only screen and (min-width: 320px) and (max-width: 736px) {
    .cardCabeceraImagen{
        display: none;
    }
    .mi-billetera {
        background-color: #0F4F80;
    }
    
}