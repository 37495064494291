.cv {
  background-image: url("../assets/img/vumOfficeImgs/background-form-1.png");
  /* background-color: #ffffff; */
}

.cv .card {
  background-color: #f9fafd;
}

@media (max-width: 700px) {
  .cv .card .card-header {
    text-align: center;
  }
}

.navbarPaddingCV {
  background-color: #f9fafd;
  font-family: Raleway !important;
}

.cv .nav-link.active {
  background-color: #816ddd;
}

.cv .nav-link {
  color: #707070;
}

.cv hr {
  color: #d8e2ef;
}

.backGreen {
  background-color: #74e36b;
  color: #ffffff;
}

.input-hidden {
  position: absolute;
  left: -9999px;
}

.removeBorder {
  border: none;
}

/* .cv input[type="file"] {
    display: none;
} */

.custom-file-upload {
  border: 1px solid var(--falcon-input-border-color);
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
}

.cv .card-header {
  color: #373737;
  font-size: 35px;
}

.text-primaryNavbar {
  color: #1783ee;
}

/* .navbar-vertical.navbar-expand-xl+.content .navbar-top{
    background: transparent linear-gradient(272deg, #1783EE 0%, #816DDD 100%) 0% 0% no-repeat padding-box;
    opacity: 1;   
  } */

.cv .table {
  border-color: #d8e2ef;
}

.cv label.btn-outline-primary,
.cv label.btn-outline-danger {
  width: 20vh;
}

.cv .btn-check:checked + .btn-outline-primary {
  background: #dfecff 0% 0% no-repeat padding-box;
  border: 1px solid #1783ee;
  border-radius: 6px;
  opacity: 1;
  color: #2c7be5;
}

.cv .btn-check:checked + .btn-outline-danger {
  background: #e63757 0% 0% no-repeat padding-box;
  border: 1px solid #e63757;
  border-radius: 6px;
  opacity: 1;
  /* color:#2c7be5; */
}

/* #E3E3E3 color boton gris */
/* #74E36B color verde boton */

.checkGreen {
  color: #74e36b;
  opacity: 1;
}
.checkGrey {
  color: #e3e3e3;
  opacity: 1;
}
.listnavCV {
  color: #1783ee;
}

.bg-CV {
  background-color: #74e36b;
}

.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .bg-CV {
  padding-bottom: 100%;
}

.colorBackButton {
  color: #0f4f80;
  padding: 10px;
}

.navbarPaddingcv {
  height: calc(100vh - 9.9125rem) !important;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .navbarPaddingcv {
    height: calc(100vh - 8.9125rem) !important;
  }
}

.navbar-vertical .navbar-vertical-label {
  font-size: 14px;
}

.colorSquare {
  color: #1783ee;
}

.colorTrash {
  color: #ed635a;
}

.text-dangerpdf {
  color: #ed635a;
}

.swal2-icon.swal2-success {
  border-color: #6afa46;
  color: #6afa46;
}

.swal2-icon.swal2-warning {
  border-color: #fab239;
  color: #fab239;
}

.swal2-icon.swal2-error {
  border-color: #fa746b;
  color: #fa746b;
}

.btn-calificanos {
  background-color: #74e36b;
  color: #0f4f80;
  border-radius: 25px;
}

.sizeTextFaces {
  font-size: 8px;
}

.line-calificanos {
  color: #74e36b;
}

:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #ffffff;
  z-index: -1;
  border-radius: 0.5em;
}

.imgAnswers {
  height: 4rem;
}

.labelTextAnswers {
  font-size: 8px;
}


.volver{
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {


  .volver{
      display: block;
      width: 40%;
      height: 50px;
      margin-left: 30%;
  }

}


@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {


  .volver{
      display: block;
      width: 40%;
      height: 50px;
      margin-left: 30%;
  }

}

/* INICIO CSS PLAN ENTRENAMIENTOS */

.card .card-entranamientosTotal .card-body,
.card .card-entranamientosFinalizados .card-body,
.card .card-entranamientosPendientes .card-body{
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.card-entranamientosTotal{
  border-bottom: 4px solid #403F84;
}

.card-entranamientosFinalizados{
  border-bottom: 4px solid #24B900;
}

.card-entranamientosPendientes{
  border-bottom: 4px solid #FF0000;
}

/* FIN */