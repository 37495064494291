.anchoImagen3 {
  width: 600px;
  height: 65px;
  }
  
  @media (max-width: 900px) {
    .anchoImagen3 {
      width: 320px;
    height: 35px;

    }
  }


  .tituloBienvenido3 {
    color: #1780E8;
}

.fontFooter3 {
  font-weight: 600;
  font-size:x-small;
}