/*
    Se crea este archivo para que cuando sea móvil quite el overflow visible, ya que en celular se vería mal
    Pero se deja el overflow visible porque en pc se ve mal si no lo tiene
*/
.columnaSelect {
    width: 400px;
    min-width: 400px;
}

.overflowSelects {
    overflow: visible;
}

@media only screen and (max-width:767px) {
    .overflowSelects {
        overflow: auto;
    }
}