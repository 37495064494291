.anchoImagen {
    width: 500px;
height: 55px;
  }
  
  @media (max-width: 900px) {
    .anchoImagen {
      width: 320px;
    height: 35px;

    }
  }

  .tituloBienvenido {
    color: #1780E8;
}

.fontFooter {
    font-weight: 600;
    font-size:x-small;

}