.btnIniciarSesion {
    border: 1px;
    border-radius: 25px;
    height: 50px;
    width: 300px !important;
    display: inline !important;
    background-color: #74E36B;
    color: #505050;
}

.btnContratistas{
    border: 1px;
    border-radius: 25px;
    height: 50px;
    width: 160px !important;
    display: inline !important;
    background-color: #1780E8;
    color: white;
    font-weight: 600;
    transition: 0.4s;
    padding: 0;
    line-height: 15px;
}

.btnContratistas:hover{
    background-color: #1566b6;
    transition: 0.4s;
    color: white;
}

.tituloBienvenidos {
    color: #5e6e82;
}
.tituloBienvenido {
    color: #1780E8;
}

.inputsFormulario {
    text-align: center;
    border: 1px solid #1780E8;
    border-radius: 1.5em;
    width: 300px;
height: 50px;
    display: inline;
    background-color: #E8EAED;
}

.login {
    background-image: url("../../assets/img/vumOfficeImgs/background-autenticacion.png");
}

.fontBienvenido {
    font-weight:900;
    
}

.labelInpiut {
    font-size: 0.9rem;
}

.inputWithIcon {
    width: 300px !important;
    display: inline-flex;
}

/* .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 1.5em !important;
    border-bottom-right-radius: 1.5em !important;
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 1.5em !important;
    border-bottom-left-radius: 1.5em !important;
} */

#iconEye {
    cursor: pointer;
}

.fontLinkLabel {
    font-weight: 800;
}

.fontFooter {
    font-weight: 600;
    font-size: 14px;
}


.divContratistas{
    display: none;
}

/* #divPassword{
    display: none;
} */

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
    .btnIniciarSesion,
    .inputsFormulario,
    .inputWithIcon {
        width: 100% !important;
    }
    .fontBienvenido {
        font-weight: 700;
        line-height: 40px;
        font-size: 30px !important;
    }
    .btnContratistas{
        width: 100% !important;
        margin-top: 10px !important;
        
    }
}

@media only screen and (min-width: 320px) and (max-width: 736px) {
    .btnIniciarSesion,
    .inputsFormulario,
    .inputWithIcon {
        width: 100% !important;
    }
    .fontBienvenido {
        font-weight: 700;
        line-height: 40px;
        font-size: 30px !important;
    }
    .btnContratistas{
        width: 100% !important;
        margin-top: 10px !important;
        
    }
}

#contr {
    font-size: 11px !important;
    color: dodgerblue;
}

/* .vumService{
    display: none;
} */

.pointer{
    cursor: pointer;
}

.swal2-popup{
    width: 40rem !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}



