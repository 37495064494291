.incapacidadRadicar{
    background: #655FBF;
}

.labelSinMargin{
    margin-bottom: 4px;
}

#tituloDataTableDesktop{
    display: none;
}

.pillelo{
    z-index: 99999999999;
}


.spanTituloDataTableDesktop{
    font-style: italic; 
    font-size: 14px;
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
    #tituloDataTableDesktop{
        display: unset;
    }    
}

@media only screen and (min-width: 320px) and (max-width: 736px) {
    #tituloDataTableDesktop{
        display: unset;
    }    
}