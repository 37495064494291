
.content-term{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    display: block;
}

.header-term{
    width: 100%;
    height: 65px;
    border: black 1px solid;
    display: flex;
    margin-bottom: 20px;

}

.div-icon{
    width: 20%;
    height: 100%;
    border-right: black 1px solid;
    padding: 1rem;
    /* background-color: gray; */

}

.image{
    width: 70%;
    height: 95%;
    margin-left: 10%;
}

.div-title{
    width: 60%;
    height: 100%;
    display: block;
    border-right: black 1px solid;
    /* background-color: green; */

}

.div-codigo{
    width: 20%;
    height: 100%;
    display: block;
    /* background-color: black; */

}

.title-top{
    width: 100%;
    height: 50%;
    border-bottom: black 1px solid;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.1rem;
    /* background-color: yellow; */
}

.title-bottom{

    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.1rem;
    /* background-color: gray; */

}

.size1{
    font-size: 12px;
}

.size1-2{
    font-size: 10px;
}

.size2{
    font-size: 10px;
}

.padding2{
    padding: 0.5rem;
}

.text-content{

    width: 100%;
    height: auto;
    text-align: start;
    /* background-color: aqua; */

}



@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {


    .header-term{
        width: 100%;
        height: auto;
        border: black 1px solid;
        display: block;
        margin-bottom: 20px;
    
    }
    
    .div-icon{
        width: 100%;
        height: 65px;
        border-right: none;
        border-bottom: black 1px solid;
        padding: 1rem;
        /* background-color: gray; */
    
    }
    
    .image{
        margin-left: 10%;
        width: 70%;
        height: 95%;
    }
    
    .div-title{
        width: 100%;
        height: 130px;
        display: block;
        border-right: none;
        border-bottom: black 1px solid;
        /* background-color: green; */
    
    }
    
    .div-codigo{
        width: 100%;
        height:65px;
        display: block;
        /* background-color: black; */
    
    }
    
    .title-top{
        width: 100%;
        height: 50%;
        border-bottom: black 1px solid;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.1rem;
        /* background-color: yellow; */
    }
    
    .title-bottom{
    
        width: 100%;
        height: 50%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.1rem;
        /* background-color: gray; */
    
    }
    
    .size1{
        font-size: 12px;
    }
    
    .size1-2{
        font-size: 10px;
    }
    
    .size2{
        font-size: 10px;
    }
    
    .padding2{
        padding: 0.5rem;
    }
    
    .text-content{
    
        width: 100%;
        height: auto;
        text-align: start;
        /* background-color: aqua; */
    
    }

}


@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {


    .header-term{
        width: 100%;
        height: auto;
        border: black 1px solid;
        display: block;
        margin-bottom: 20px;
    
    }
    
    .div-icon{
        width: 100%;
        height: 65px;
        border-right: none;
        border-bottom: black 1px solid;
        padding: 1rem;
        /* background-color: gray; */
    
    }
    
    .image{
        margin-left: 10%;
        width: 70%;
        height: 95%;
    }
    
    .div-title{
        width: 100%;
        height: 130px;
        display: block;
        border-right: none;
        border-bottom: black 1px solid;
        /* background-color: green; */
    
    }
    
    .div-codigo{
        width: 100%;
        height:65px;
        display: block;
        /* background-color: black; */
    
    }
    
    .title-top{
        width: 100%;
        height: 50%;
        border-bottom: black 1px solid;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.1rem;
        /* background-color: yellow; */
    }
    
    .title-bottom{
    
        width: 100%;
        height: 50%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.1rem;
        /* background-color: gray; */
    
    }
    
    .size1{
        font-size: 12px;
    }
    
    .size1-2{
        font-size: 10px;
    }
    
    .size2{
        font-size: 10px;
    }
    
    .padding2{
        padding: 0.5rem;
    }
    
    .text-content{
    
        width: 100%;
        height: auto;
        text-align: start;
        /* background-color: aqua; */
    
    }

}

