.ayudaForm {
    background: #655FBF
}

.inputRadioOtraRespuestaAyuda{
    margin-right: 10px;
}

#divNumeroTelefonicoAyuda{
    display: none;
}

.btnEnviarAyuda{
    background-color: #1783EE;
    border: 1px solid #1783EE;
}

.divsModalAyuda{
    text-align: left;
}