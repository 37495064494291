#space-availiable {
    background-image: url("../../assets/img/vumOfficeImgs/background-bienestar.png") !important;
    background-size: cover !important;
}

.bienestar {
    background-image: url("../../assets/img/vumOfficeImgs/background-bienestar.png");
    background-size: 100% 100% !important;
    
}

.tituloBienvenidoRrhh{
    color: white;
}

.logoVumBienvenido{
    width: 90px;
    margin-left: 0.2em;
}

.paddingRRHH{
    padding-top: 70px;
}

.cabeceraRRhh{
    text-align: left;
}

.imgbtnRrhh{
    width: 8%;
    margin-right: 10px;
}

.tituloModulo{
    color: white;
    font-weight: 800;
}

.spanNameUserRRHH{
    font-size: 21px;
    font-weight: 500;
    color: white;
}

.copyPage{
    color: white;
    max-width: 750px;
}

.btns-rrhh{
    border: 1px solid #f9fafd;
    border-radius: 25px;
    height: 50px;
    width: 300px;
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    color: white;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: .3125rem 1rem;
    margin-right: 20px !important;
    font-size: 1rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.zoomBtn {
    transition: transform .3s;
}

.zoomBtn:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    transition: transform .3s;
}

.form-label{
    /* color: white; */
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
    .cabeceraRRhh{
        text-align: center;
    }
    .btns-rrhh{
        width: 100%;
    }
    .paddingRRHH{
        padding-left: 15px;
        padding-top: unset;
    }
    .copyPage {
        max-width: unset;
        text-align: center;
    }
    .divTitulosRRHH{
        text-align: center;
    }
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {
    .cabeceraRRhh{
        text-align: center;
    }
    .btns-rrhh{
        width: 100%;
    }
    .paddingRRHH{
        padding-left: 15px;
        padding-top: unset;
    }
    .copyPage {
        max-width: unset;
        text-align: center;
    }
    .divTitulosRRHH{
        text-align: center;
    }
}