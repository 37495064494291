.IncapacidadConsultar{
    background: #655FBF;
}
.iconoDocumentos{
    font-size: 20px;
}

body {
    padding-right: unset !important;
}

.headersDataTableModal {
    background-color: #EDF2F9 !important;
    color: #344050 !important;
    font-size: 14px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    border-bottom: 1px solid black;
}

.trDesahbilitado{
    background: #e9ecef;
    cursor: not-allowed;
}

input[type="file"]:disabled{
    cursor: not-allowed;
}

.tbodyModal,.tbodyModal>input[type="file"]{
    font-size: 15px;
}

.modalActualizarDocumentos {
    width: 800px !important;
}