.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #2c7be5 !important;
}

.container-term{
    width: 100%;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem;
    /* background-color: red; */
}