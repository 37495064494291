.pl {
    padding-left: 15px !important;
}

.mt30px{
    margin-top: 30px !important;
}

.width100{
    width: 100% !important;
}

.width33{
    width: 33.33% !important;
}

.textCenter{
    text-align: center;
}

.fontText{
    font-size: inherit;
    font-weight: inherit;
    font-family: Raleway !important;
}