.desarrollo {
    background:red;
}

.plusIcon{
    color: #2c7be5;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.plusIcon:hover{
    transform: scale(1.1);
}

.divTrashIcon{
    padding-left: 0px;
    padding-right: 0.5px;
    text-align: center;
    margin-top: -36px;
    height: calc(1.5em + 0.625rem + 2px );
    border: 1px solid #d8e2ef;
    padding-top: 3px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.trashIcon{
    color: red;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.trashIcon:hover{
    transform: scale(1.1);
}

.divOtroMedio {
    padding-left: 0em;
}

.form-check .otroMedio {
    float: none;
    /* margin-left: 1px;  */
}

.paddingBtn {
    padding-left: 5% !important;
    padding-right: 5% !important;
}

.custom-file-container {
    display: flex;
    align-items: center;
    /* gap: 10px; */
}

.custom-file-button {
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    background-color: #343a40;
    color: white;
    border: 1px solid #343a40;
    border-radius: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.custom-file-input {
    display: none;
}

.custom-file-label {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #f8f9fa;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
  
  @media (max-width: 900px) {
    .custom-file-button {
   
        padding: 0.375rem 0.75rem;
        cursor: pointer;
        background-color: #343a40;
        color: white;
        border: 1px solid #343a40;
        border-radius: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        font-size: x-small;

    }
    .custom-file-input {
        display: none;
    }

    .custom-file-label{
        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        background-color: #f8f9fa;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        font-size: x-small;
    }
  }
  .tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -50px; 
    opacity: 0;
    transition: opacity 0.5s;
    font-size: x-small;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-container .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 41%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}