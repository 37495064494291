.dashboard {
  background-position-y: 25%;
  background-attachment: fixed;
  background: #403F84 !important
}

.btnVumOffice {
  border: 1px;
  border-radius: 25px;
  height: 50px;
  /* width: 300px !important; */
  display: inline !important;
  background-color: #74e36b;
  color: #505050;
}

.fontBtnVumOffice {
  font-weight: 900;
  font-size: large;
}

.imgVumHeader {
  width: 20%;
}
.imgVumHeader2 {
  width:30%
}

.spanNombreUsuario {
  font-size: 15px;
  font-weight: 100;
  color: white;
}

.tituloComoTePodemosAyudar {
  margin-bottom: 0px;
  color: white;
}

.imgControlIngresoYSalida {
  width: 8%;
}

.copyFooter {
  color: white;
  margin-bottom: 0;
  padding-bottom: 1rem;
  font-size:x-small;
}

#headerMovil {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
  .btnVumOffice {
    width: 100% !important;
    padding: 1%;
  }
  .imgVumHeader {
    display: none;
  }
  #root {
    padding: 0 20px 10px 20px !important;
  }
  .paddingRRHH {
    padding-left: 0 !important;
  }
  #headerDesktop {
    display: none;
  }
  #headerMovil {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {
  .btnVumOffice {
    width: 100% !important;
    padding: 1%;
  }
  .imgVumHeader {
    display: none;
  }
  #root {
    padding: 0 1rem !important;
  }
  #headerDesktop {
    display: none;
  }
  #headerMovil {
    display: block;
  }
}
