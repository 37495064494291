.anchoImagen2 {
    width: 600px;
height: 65px;
  }
  
  @media (max-width: 900px) {
    .anchoImagen2 {
      width: 320px;
    height: 35px;

    }
  }

  .tituloBienvenido {
    color: #1780E8;
}

.fontFooter {
    font-weight: 600;
    font-size:x-small;

}