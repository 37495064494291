
.nombre{
    display: none;
}

.sombraCards {
    -webkit-box-shadow: 6px 6px 13px -1px rgba(0, 0, 0, 0.41);
    -moz-box-shadow: 6px 6px 13px -1px rgba(0, 0, 0, 0.41);
    box-shadow: 6px 6px 13px -1px rgba(0, 0, 0, 0.41);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.imgHeaderCard {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.oficinaBotomCopy {
    background-color: #1780E8;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 180px;
    max-height: 180px;
}

.tittleCardOficina {
    font-weight: 500;
    color: white;
    font-size: 29px;
}

.bodyCardOficina {
    font-weight: 200;
    color: white;
    font-size: 14px;
}

.zoom {
    transition: transform .2s;
}

.zoom:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(0.9);
    transition: transform .1s;
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {

    

    .oficinaBotomCopy {
        width: auto;
        height: auto;
        max-height: unset;
        display: none;
    }

    .container-img{
        display: flex;
    }

    .imgHeaderCard{
        width: 30%;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px; 
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
    }

    .nombre{
        display: block;
        width: 70%;  
        border-top-right-radius: 6px; 
        border-bottom-right-radius: 6px;
        padding: 1rem;
        background-color: #1780E8;
        font-weight: 500;
        color: white;
        font-size: 1.1em;
    }
}

@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: landscape) {

    .oficinaBotomCopy {
        width: auto;
        height: auto;
        max-height: unset;
        display: none;
    }

    .container-img{
        display: flex;
    }

    .imgHeaderCard{
        width: 30%;
    }

    .nombre{
        display: block;
        width: 70%;  
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 0.5rem;
        background-color: #1780E8;
        font-weight: 500;
        color: white;
        font-size: 1.1em;
    }
}