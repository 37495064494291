.rrhhForm {
    background-image: url("../../../assets/img/vumOfficeImgs/background-rrhh.png");
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
}

.bg-image-vum{
    background-image: url("../../../assets/img/vumOfficeImgs/background-rrhh-hastag.png");
    background-size: 180px;
}

.inputRadioOtraRespuesta{
    margin-right: 10px;
}

#divNumeroTelefonico{
    display: none;
}

.btnEnviar{
    background-color: #1783EE;
    border: 1px solid #1783EE;
}

.divsModal{
    text-align: left;
}

.canvas{
    width: '300px';
    height: '150px';
    border: 1px solid #000000;

}